import React from 'react'
import { StyledArrow, ArrowInner } from './styles'

const Arrow = ({ onClick, className, style, left }) => (
  <StyledArrow
    left={left}
    onClick={onClick}
    className={className}
    style={{ ...style }}
  >
    <ArrowInner left={left} />
  </StyledArrow>
)

export default Arrow
