import React from 'react'
import useEventsQuery from './useEventsQuery'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import EventsSlider from '../../components/EventsSlider/EventsSlider'

const Events = () => {
  const events = useEventsQuery().filter(({ disabled }) => !disabled)

  return <EventsSlider events={events} />
}

export default Events
