import React from 'react'
import SEO from '../components/Seo/Seo'
import Layout from '../components/Layout/Layout'
import Events from '../containers/Events/Events'

const IndexPage = () => (
  <Layout>
    <SEO title="RSVP" />
    <Events path="/" />
  </Layout>
)

export default IndexPage
