import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import Arrow from './Arrow'
import { StyledSlider, SliderContainer } from './styles'
import EventView from '../EventView/EventView'
import useModal from '../../hooks/useModal'
import { registerForEvent } from './actions'
import Modal from '../shared/Modal/Modal'
import useEventsRequest from '../../containers/Events/useEventsRequest'

const messages = {
  login: 'LOGIN',
  close: 'CLOSE',
  registerForEvent: 'REGISTER_FOR_EVENT',
}

const EventsSlider = ({ events }) => {
  useEventsRequest()
  const { isOpen, closeModal, openModal } = useModal()

  const {
    profile: {
      isAuthenticated,
      entity: { id: userId },
    },
  } = useSelector(({ profile }) => ({
    profile,
  }))

  const { byId } = useSelector((state) => state.events)

  const dispatch = useDispatch()

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <Arrow />,
    prevArrow: <Arrow left />,
  }

  const handleRegisterForEvent = (event, userID) => {
    openModal()
    if (event.rsvpList && event.rsvpList.includes(userId)) {
      return
    }
    const eventId = event.id
    dispatch(registerForEvent({ eventId, userId: userID }))
    postMessage(messages.registerForEvent, event)
  }

  const handleOpen = () => {
    const loginUrl = process.env.GATSBY_LOGIN_URL
    window.location.href = loginUrl
  }

  const handleClick = (e, event) => {
    if (!isAuthenticated) {
      handleOpen(e)
    } else {
      handleRegisterForEvent(event, userId)
    }
  }

  return (
    <StyledSlider style={{ minHeight: '100vh' }}>
      <SliderContainer>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider {...sliderSettings}>
          {events.map((event) => {
            const isRegisteredForEvent =
              byId &&
              byId[event.id].rsvpList &&
              byId[event.id].rsvpList.includes(userId)
            return (
              <div key={event.id}>
                <Link to={event.id}>
                  <EventView
                    isRegisteredForEvent={isRegisteredForEvent}
                    event={event}
                    onClick={(e) => {
                      e.preventDefault()
                      handleClick(e, event)
                    }}
                    poster={event.poster}
                    posterVertical={event.posterVertical}
                  />
                </Link>
              </div>
            )
          })}
        </Slider>
      </SliderContainer>

      <Modal open={isOpen} onClose={closeModal}>
        <p>Для продовження будь ласка перейдіть у ваш Telegram месенджер</p>
      </Modal>
    </StyledSlider>
  )
}

export default EventsSlider
