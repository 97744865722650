import styled from 'styled-components'
import { breakpoints } from '../../styles/constants'

export const StyledSlider = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SliderContainer = styled.div`
  width: 100%;
  max-width: 992px;
`

export const StyledArrow = styled.button`
  top: 50%;
  transform: translateY(-50%);
  left: ${(p) => p.left && 0};
  right: ${(p) => !p.left && 0};
  z-index: 1;
  box-sizing: border-box;
  width: 50px;
  height: 100%;
  padding: 0;
  font: inherit;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.2);

  &::before {
    display: none;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media (min-width: ${breakpoints.lg}) {
    left: ${(p) => p.left && '-100px'};
    right: ${(p) => !p.left && '-100px'};
  }
`

export const ArrowInner = styled.span`
  position: absolute;
  top: 50%;
  margin-top: -30px;
  left: ${(p) => p.left && '15px'};
  right: ${(p) => !p.left && '15px'};
  display: block;
  width: 50px;
  height: 50px;
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform: ${(props) => (props.left ? 'rotate(-135deg)' : 'rotate(45deg)')};
`
