import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  const {
    allEvent: { nodes },
  } = useStaticQuery(graphql`
    query EventsQuery {
      allEvent {
        nodes {
          id__normalized
          name
          disabled
          startTime {
            utc
            tz
          }
          venue
          poster {
            local {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          posterVertical {
            local {
              childImageSharp {
                fluid(maxWidth: 1080, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          rsvpButton {
            pending
            registered
            unavailable
            unregistered
          }
          products
          rsvpLeft
          rsvpLimit
          rsvpList
        }
      }
    }
  `)

  return (
    nodes && nodes.map(({ id__normalized: id, ...rest }) => ({ id, ...rest }))
  )
}
