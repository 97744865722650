import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getEvents } from './actions'

export default () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEvents())
  }, [dispatch])
}
